<template>
  <div>
    <div
      class="_main_card_header"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
      ">
      <div>
        <div class="__side_nav" style="display: flex; gap: 1rem">
          <button
            class="btn"
            :class="[
              $route.name === 'Fraud Messages' ? '__side_item_active' : '',
            ]"
            @click="
              $router.replace('/admin/fraud-detection').catch((err) => {})
            ">
            BMS Fraudulent Messages ({{ messages_count }})
          </button>
          <button
            class="btn"
            :class="[
              $route.name === 'Reseller Fraud Messages'
                ? '__side_item_active'
                : '',
            ]"
            @click="
              $router
                .replace('/admin/fraud-detection/reseller')
                .catch((err) => {})
            ">
            Resellers Fraudulent Messages ({{ reseller_message_count }})
          </button>
        </div>
      </div>
      <div class="__header_inner admin_header_padding">
        <div
          class="side_nav_wrapper"
          style="overflow-x: auto; min-height: 3rem; margin-left: auto">
          <!-- <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/fraud-detection'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/fraud-detection').catch((err) => {})
              ">
              Messages ({{ messages_count }})
            </button>
          </div> -->
          <!-- <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/fraud-detection/whitelist'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/fraud-detection/whitelist')
                  .catch((err) => {})
              ">
              White Listed Users
            </button>
          </div> -->
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/fraud-detection/keywords'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/fraud-detection/keywords')
                  .catch((err) => {})
              ">
              Manage Keywords ({{ keywords_count }})
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main_card_body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        messages_count: 0,
        keywords_count: 0,
        reseller_message_count: 0,
      };
    },
    mounted() {
      this.$store
        .dispatch("admin/fraudSystem/getFraudulentMessages", {
          page: 1,
          search: "",
        })
        .then((data) => {
          this.messages_count = data.total;
        });
      this.$store
        .dispatch("admin/fraudSystem/getKeywords", {
          page: 1,
          search: "",
        })
        .then((data) => {
          this.keywords_count = data.total;
        });
      // !FETCH RESELLER
      this.$store
        .dispatch("admin/fraudSystem/getResellerFraudulentMessages", {
          page: 1,
          search: "",
        })
        .then((data) => {
          this.reseller_message_count = data.total;
        });
    },
  };
</script>

<style scoped></style>
