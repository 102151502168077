var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"_main_card_header",staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',[_c('div',{staticClass:"__side_nav",staticStyle:{"display":"flex","gap":"1rem"}},[_c('button',{staticClass:"btn",class:[
            _vm.$route.name === 'Fraud Messages' ? '__side_item_active' : '',
          ],on:{"click":function($event){_vm.$router.replace('/admin/fraud-detection').catch((err) => {})}}},[_vm._v(" BMS Fraudulent Messages ("+_vm._s(_vm.messages_count)+") ")]),_c('button',{staticClass:"btn",class:[
            _vm.$route.name === 'Reseller Fraud Messages'
              ? '__side_item_active'
              : '',
          ],on:{"click":function($event){_vm.$router
              .replace('/admin/fraud-detection/reseller')
              .catch((err) => {})}}},[_vm._v(" Resellers Fraudulent Messages ("+_vm._s(_vm.reseller_message_count)+") ")])])]),_c('div',{staticClass:"__header_inner admin_header_padding"},[_c('div',{staticClass:"side_nav_wrapper",staticStyle:{"overflow-x":"auto","min-height":"3rem","margin-left":"auto"}},[_c('div',{staticClass:"__side_nav"},[_c('button',{staticClass:"btn",class:[
              _vm.$route.path == '/admin/fraud-detection/keywords'
                ? '__side_item_active'
                : '',
            ],on:{"click":function($event){_vm.$router
                .replace('/admin/fraud-detection/keywords')
                .catch((err) => {})}}},[_vm._v(" Manage Keywords ("+_vm._s(_vm.keywords_count)+") ")])])])])]),_c('div',{staticClass:"main_card_body"},[_c('router-view')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }